.page{
    width: 100%;
    height: 100%;
    position: absolute;
}


.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}

.contact-page,
.about-page{
    .text-zone {
        position:absolute;
        left: 12%;
        top: 50%;
        transform: translateY(-50%);
        width: 70%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;

    }

    h1 {
        font-size: 53px;
        color: #75FBB6;
        margin-top: 0;
        position: relative;
        margin-bottom: 40px;
        left: 10px;
        animation: pulse 1s;
        

    }

    p {
        font-size: 20px;
        color: white;
        font-family: sans-serif;
        min-width: fit-content;
        animation: fadeInRight .5s;
        margin-left: 20px;

        &:nth-of-type(1) {
            animation-delay: .6s;
        }
        &:nth-of-type(2) {
            animation-delay: .7s;
        }       
         &:nth-of-type(3) {
            animation-delay: .8s;
        }
        &:nth-of-type(4) {
            animation-delay: .9s;
        }
    }
}
