.projects-page {
    padding-top: 20px;
    overflow: auto;
    display: block;
    width: 100%;
    height: 90%;
    top: 10%;
    margin: 0 auto;
    z-index: 1;
    animation-delay: 1s;

    h1 {
        color: #75FBB6;
        margin-left: 110px;
        font-size: 40px;
    }
    p{
        color: #75FBB6;
        margin-left: 80px;
        font-size: 15px;
        font-weight: 400;

    }



}


.collapsible{
    margin-left: 75px;
    animation-fill-mode: forwards;

    .title{
      
        display: flex;
        h1{

            font-size: 30px;
            margin-bottom: 10px;
            width: 50%;

        }
        img{
          margin-top: 25px;
          margin-left: 50px;
          width: 40px;
          height: 40px;
        }


    }

    .content{
      width: 65%;
      margin-left: 100px;
    }

}

.title{
    font-size: 40px;
}

#sub-heading{
    animation: fadeIn .5 2s;
    color: #75FBB6;
    margin-left: 150px;
    font-size: 20px;
    font-weight: 300;
    animation: fadeIn .5 2s;
}

.github-link{
    margin-left: 80px;
    font-size: 15px;

}